.trigger-map {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 40rem;
  overflow-x: scroll;
}

.trigger-map-with-labels {
  /* display: grid;
  grid-template-columns: 1fr 19fr;
  padding: 0px 8px 0px 24px;
  position: relative; */
  display: flex;
  flex: 1 1;
}

/* .trigger-map-labels {
  margin-top: 30px;
} */

/* .trigger-map-labels img {
  width: 24px;
  height: 24px;
  margin: 3px 0px;
} */

.mood-categories {
  padding: 1rem 2.5rem;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.mood-category {
  gap: 0.25rem;
}

.mood-category-label {
  font-size: 0.725rem;
  /* identical to box height, or 15px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.responsive-container {
  /* position: relative;
  width: 600px;
  max-width: 600px; */
  overflow-x: scroll;
  /* overflow-y: hidden; */
}

.responsive-container-body {
  height: 100%;
}
