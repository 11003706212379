html {
  font-size: 16px;
}

@media screen and (max-width: 1919px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 1550px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 1350px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 640px) {
  html {
    font-size: 8px;
  }
}

.app {
  font-family: "Poppins";
  font-style: normal;
  /* max-width: 1800px; */
  height: 100vh;
  width: 100%;
}

.main-content-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 4rem);
  margin-left: 4rem;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #282860;
}

.sub-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  display: flex;
  align-items: center;

  color: #282860;
}

.subheading-white {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;
}

.info-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
}

.info-text-white {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;

  opacity: 0.7;
}

.z-index-high {
  position: relative;
  z-index: 1000;
}

.text-center {
  align-items: center;
  text-align: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-flex-start {
  justify-content: flex-start;
}

.font-w500 {
  font-weight: 500;
}

.display-block {
  display: block;
}

/* Shared styles for modal components */

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.modal-content {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.02);
  border-radius: 24px;
  position: relative;
  width: 700px;
  overflow-y: scroll;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 40px;
}

.modal-title {
  margin: 0;
}

.modal-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;

  color: #282860;
}

.modal-header-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.modal-content-divider {
  width: 100%;
  height: 2px;
  background: #dde2e9;
  opacity: 0.5;
}

.modal-subheading {
  font-weight: 500;
  opacity: 0.7;
  line-height: 180%;
  margin-bottom: 8px;
}

/* Styles for input fields */

.text-input-field {
  flex-grow: 0.8;
  background: rgba(221, 226, 233, 0.15);
  border: 1px solid #dde2e9;
  border-radius: 8px;
  height: 40px;
  outline: none;
  padding: 10px 22px;
  margin-right: 24px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.0125em;

  color: #282860;
}

.text-input-field::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0125em;
  color: #282860;
  opacity: 0.5;
}

.form-input-field-group {
  margin-top: 24px;
}

.form-radio-input {
  width: 22px;
}

.cursor-pointer {
  cursor: pointer;
}

* {
  -ms-overflow-style: none;
}
/* ::-webkit-scrollbar {
  display: none;
} */

.scroll-remove::-webkit-scrollbar {
  width: 0;
}
.scroll-thin,
.scroll-thin-vertical {
  scrollbar-width: thin;
  scrollbar-color: #cdd1f4 #ffffff;
  scrollbar-gutter: stable;
}
.scroll-thin::-webkit-scrollbar {
  width: 14px;
}

.scroll-thin::-webkit-scrollbar-track,
.scroll-thin-vertical::-webkit-scrollbar {
  background-color: transparent;
  border-radius: 100px;
  margin: 0 30px;
}

.scroll-thin::-webkit-scrollbar-thumb,
.scroll-thin-vertical::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: #cdd1f4;
  background-clip: content-box;
}

.scroll-thin-vertical::-webkit-scrollbar {
  width: 16px;
}

.scroll-thin-vertical::-webkit-scrollbar-track {
  margin: 20px 0;
}

/* The date and time pickers appears behind the modal, using a higher value of z-index avoids it. */
.MuiPickersPopper-root.MuiPopper-root {
  z-index: 1000000;
}

.MuiInputBase-root.MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.Mui-focused.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.close-modal-btn {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.ring-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media screen and (max-width: 1440px) {
  .main-content-wrapper {
    height: unset;
  }
}
