.email-settings-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.email-settings-form {
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
