.clients-tasks-container {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.02);
  border-radius: 1.5rem;
  padding: 1rem;
  height: 100%;
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
}

.clients-tasks-subheading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;

  display: flex;
  align-items: center;

  color: #282860;
}

.clients-task-metrics-container {
  padding: 1.5rem 0px;
  /* display: grid; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
  /* grid-template-rows: repeat(3, auto); */
  /* row-gap: 0.75rem; */
  border-bottom: 2px dashed rgba(40, 40, 96, 0.5);
}

.client-task-metric {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  max-height: 9rem;

  /* grid-template-columns: auto 1fr; */
  min-height: unset;
}

.client-task-metric img {
  height: 100%;
  max-height: 9rem;
  /* height: 100%; */
  object-fit: cover;
  object-position: center;
}

.client-task-metric-data-container {
  padding: 0 1.5rem;
}

.client-task-metric-subheading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  /* identical to box height, or 122% */

  text-align: center;
  color: #282860;

  opacity: 0.7;
}

.client-task-metric-value {
  background: rgba(171, 178, 242, 0.25);
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 0.75rem;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  /* margin-top: 1rem; */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  /* line-height: 22px; */
  /* or 69% */

  text-align: center;

  color: #282860;
}

.tasks-metrics-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.dashboard-tasks-container {
  margin-top: 2.5rem;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1rem;
  padding: 2rem;
  flex: 1 1 auto;
  /* height: 270px; */
}

.dashboard-tasks-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;
}

.dashboard-tasks-show-all {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  display: flex;
  align-items: center;

  color: #5f6ce1;
  cursor: pointer;
}

/* .report-tasks-list {
  overflow-x: hidden;
  overflow-y: overlay;
  max-height: 16rem;
} */

.report-task-text-group input[type="checkbox"] {
  width: 1rem;
  align-items: center;
}

.report-task-text-descp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;

  color: #282860;

  opacity: 0.8;

  margin-left: 1rem;
}

.report-task-date {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;

  text-align: right;

  color: #282860;

  opacity: 0.5;
}

@media screen and (max-width: 1440px) {
  .clients-tasks-container {
    height: unset;
  }
  .tasks-metrics-wrapper {
    flex-direction: row;
    gap: 2rem;
  }
  .clients-task-metrics-container {
    flex-direction: row;
    border-bottom: none;
    border-right: 2px dashed rgba(40, 40, 96, 0.5);
    padding: 0;
    padding-right: 1.5rem;
    align-items: center;
    height: 100%;
    flex: initial;
  }
  .client-task-metric {
    max-height: 11rem;
    height: 100%;
  }
  .client-task-metric img {
    max-height: 11rem;
    object-fit: cover;
    height: 100%;
    width: 50%;
  }
  .dashboard-tasks-container {
    margin: 0;
    flex: 1 1 auto;
    overflow-y: overlay;
    max-height: 11rem;
    min-width: 25rem;
  }
}
