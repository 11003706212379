.client-search-result {
  padding-top: 1.5rem;
  width: calc(100%);
  /* height: calc(100%); */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  max-height: calc(100%);
  overflow-y: overlay;
}


.client-screen-wrapper {
    height: 100%;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
  
    max-width: 1920px;
    max-height: 1200px;
  }
  
  .not-exist {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 26px;
    height: 100%;
  }
  
  .not-exist-text {
    width: 70%;
    text-align: center;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }