.mental-wellbeing-status {
  flex: 1 1 auto;
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.02);
  border-radius: 1.5rem;
  /* height: 144px; */
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.mental-wellbeing-subheading {
  padding: 1.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  /* line-height: 32px; */
  /* or 160% */

  display: flex;
  align-items: center;

  color: #282860;
}

.mental-wellbeing-content {
  background: #ffffff;
  border-left: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1.5rem;
  /* height: 142px; */
  padding: 1.5rem 2rem;
  display: flex;
  gap: 2.25rem;
  align-items: center;
}

.mental-wellbeing-content img {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
  object-position: center;
}

.mental-wellbeing-content-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  /* line-height: 21px; */

  color: #282860;

  opacity: 0.8;
  padding: 2px 0px;
}

.mental-wellbeing-content-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}
