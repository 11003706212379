/* .therapist-info .star-rating-container {
  background: #FFFFFF;
  border: 1px solid rgba(40, 40, 96, 0.25);
  border-radius: 12px;
  height: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 18px 14px;
  width: 200px;
}

.star-rating-container img {
  width: 32px;
  height: 24px;
  padding-left: 8px
}

.star-rating-container .inactive {
  opacity: 0.15;
} */

.star-rating-container {
  cursor: pointer;
}

.star-rating-container {
  background: #ffffff;
  border: 1px solid rgba(40, 40, 96, 0.25);
  border-radius: 0.75rem;
  display: grid;
  grid-template-columns: repeat(5, auto);
  padding: 1rem 0.75rem;
  width: fit-content;
  justify-content: center;
}

.star-rating-container img {
  width: 100%;
  min-width: 0;
  object-fit: contain;
  object-position: center;
  height: 1.5rem;
  padding-left: 0.5rem;
}

.star-rating-container .inactive {
  opacity: 0.15;
}

.star-rating-container .inactive:hover {
  opacity: 1;
}
