.upcoming-session-container {
  /* width: 381px; */
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.upcoming-session-content {
  /* height: 192px; */
  flex: 1 1 auto;
  width: 100%;
  background: #5f6ce1;
  border: 1px solid rgba(40, 40, 96, 0.15);
  box-shadow: 0px 0.5rem 1rem rgba(41, 40, 45, 0.02);
  border-radius: 1.5rem 1.5rem 0px 0px;
  padding: 0px 0.75rem;
  z-index: 100;
}

.upcoming-session-subheading-group {
  /* height: 91px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upcoming-session-action-buttons {
  /* height: 72px; */
  background: #fbfbff;
  border: 1px solid rgba(40, 40, 96, 0.15);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.02);
  border-radius: 0px 0px 1.5rem 1.5rem;

  padding: 1rem 3rem;
  z-index: 100;
  justify-content: center;
}

.upcoming-session-reschedule-button {
  background: #5f6ce1;
  border-radius: 0.375rem;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;

  text-align: center;

  color: #fbfbff;
  padding: 0.625rem;
  min-width: 6rem;
  cursor: pointer;
}

.upcoming-session-cancel-button {
  background: #ffffff;
  border: 1px solid #ec7440;
  border-radius: 0.375rem;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;

  text-align: center;

  color: #ec7440;
  padding: 0.625rem;
  min-width: 6rem;
  cursor: pointer;
}

.upcoming-session-calendar {
  position: relative;
  top: -2rem;
}

.upcoming-session-calendar > img {
  height: 7.5rem;
  width: 7.5rem;
}

.upcoming-session-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;

  color: #ffffff;
  margin-right: 45px;
}

.upcoming-session-timeline {
  padding: 0px 2rem;
  margin-top: 1rem;
}

.upcoming-session-date,
.upcoming-session-time {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #ffffff;

  opacity: 0.8;
  margin-left: 0.625rem;
}

.upcoming-session-info-text {
  color: white;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.button-disabled {
  opacity: 0.8;
}

.upcoming-session-button-group {
  display: flex;
  gap: 1rem;
}

.upcoming-session-date-icon > img,
.upcoming-session-time-icon > img {
  height: 2.5rem;
}
