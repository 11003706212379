.report-ai-analysis-pinned {
  height: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  margin-top: 32px;
  margin-bottom: 24px;
}

.report-ai-session-text {
  height: 40px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 230%;
  /* or 37px */


  color: #282860;

  opacity: 0.8;
  text-decoration: none;
}

.report-ai-weather {
  width: 32px;
  height: 32px;
}

.report-ai-session-number {
  height: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 230%;
  /* or 28px */

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.8;
}

.report-ai-session-date {
  height: 24px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 230%;
  /* or 23px */

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.5;
}

.report-ai-session {
  background: #FFFFFF;
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 16px;
  padding: 12px;
  width: 240px;
  margin-right: 32px;
  cursor: pointer;
}

.session-link {
  text-decoration: none;
}