.overview-pinned-file {
  width: 100%;
  max-width: 15rem;
  height: calc(100%);
  /* aspect-ratio: 2/1; */
  background: rgba(171, 178, 242, 0.15);
  border: 1px solid rgba(40, 40, 96, 0.15);
  border-radius: 1rem;
  padding: 0.75rem;
  position: relative;
  margin: 0;
}

.overview-pinned-file-header {
  margin-bottom: 0.5rem;
  position: relative;
}

.overview-pinend-file-header-pin-star {
  cursor: pointer;
}

.overview-pinned-file-type {
  text-align: center;
}

.overview-pinned-file-type > a > img {
  width: 2rem;
  object-fit: contain;
  object-position: center;
}

.overview-pinned-file-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  text-align: center;

  color: #282860;

  opacity: 0.8;
}

.overview-pinned-file-name a {
  text-decoration: none;
  color: #282860;
}

.overview-pinned-file-data-history {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.635rem;

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.5;
}

.overview-pinned-file-size {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.5;
}

.overview-pinned-file-author img {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
}

.overview-pinned-file-header-dropdown {
  position: absolute;
  left: 210px;
  top: -12px;
  width: 150px;
  border-radius: 8px;
  border: 1px solid #dde2e9;
  background: #fff;
  padding: 8px;
  z-index: 1000;
}

.overview-pinned-file-header-dropdown-item img {
  margin-right: 8px;
}

.overview-pinned-file-header-dropdown-item {
  color: #282860;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 500;
  padding: 8px;
  border-bottom: 2px solid rgba(40, 40, 96, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.overview-pinned-file-header-dropdown-item:last-child {
  border-bottom: none;
}

.no-files-info {
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-files-info > div {
  font-style: italic;
  opacity: 0.7;
}

.file-changes-loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.overview-pinned-files-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 15rem);
  gap: 2rem;
  padding-bottom: 2rem;
}
